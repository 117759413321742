.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.primary-color {
  color: #3BB32D !important;
}

.primary-bg {
  background-color: #59d84b !important;
}

.Mui-disabled {
  color: #282c34 !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: #282c34 !important;

}

.login-container {
  top: 0px;
  transition: top ease 0.5s;
}

.bg-card {
  background-color: #f7f7f7 !important;
  box-shadow: none !important;
}

.login {
  background-color: #FDCB2C !important;
}

.header-color {
  background-color: #001525 !important;
}

.main-color {
  color: #001525 !important;
}

.search {
  background-color: #38405077;
}

.bg-main {
  background-image: url('/src/assets/img/bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 565px;
  box-shadow: inset 0 0 0 2000px rgba(10, 10, 10, 0.3);
}

.header-title {
  font-family: 'Poppins', sans-serif;
}

.header-body {
  font-family: 'Inter', sans-serif;
  font-size: 20px !important;
  color: #aaa;
}

.header-container {
  padding-left: 95px;
  padding-right: 181px;
}

.menu-text {
  font-size: 18px !important;
}

.toolbar-login {
  min-height: 60px !important;
  box-shadow: 0px 1px 3px -2px rgb(0 0 0 / 20%), 0px 1px 3px 0px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / 2%) !important;
}

.bookmark {
  background-image: url('/src/assets/img/bookmark.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.card-box {
  border-radius: 13px;
  box-shadow: -4px 1px 20px -1px #dedede;
}

.move {
  white-space: nowrap;
  animation-name: rightToLeft;
  animation-duration: 9.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  /* animation: rightToLeft 4.5s linear infinite; */
}

@keyframes rightToLeft {
  0% {
    transform: translateX(200px);
  }

  100% {
    transform: translateX(-760px);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}